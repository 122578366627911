import React, { useEffect, useState } from "react";
import axios from "axios";

import Profile from "./Profile";
import Header from "./Header";
// import Footer from "../footer";
import Loading from "../Loading";
import Footer from '../Home/Footer';

const ProfileTrainer = (props) => {
  const [user, SetUser] = useState();
  const [isLoading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const urlUserData = `${process.env.REACT_APP_API}api/userData/`;
    try {
      axios.get(urlUserData, config).then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        SetUser(response.data.data);
        setLoading(false);
      });
    } catch (err) {}
  }, []);
  const [error, setError] = useState("");

  const [allCourses, setAllCourses] = useState();

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    try {
      const url = `${process.env.REACT_APP_API}api/courses`;
      await axios.get(url).then((res) => {
        setAllCourses(res.data.data);
      });
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
      }
    }
  };

  const [date, setDate] = React.useState();

  const [cart, setCart] = useState(false);
  const [profile, setProfile] = useState(true);
  const [courses, setCourses] = useState(false);
  const [trainings, setTrainings] = useState(false);

  const handleCart = () => {
    setCart(true);
    setProfile(false);
    setTrainings(false);
    setCourses(false);
  };
  const handleCourses = () => {
    setCart(false);
    setProfile(false);
    setCourses(true);
    setTrainings(false);
  };
  const handleTrainings = () => {
    setCart(false);
    setProfile(false);
    setCourses(false);
    setTrainings(true);
  };
  const handleProfile = () => {
    if (!profile) {
      setProfile(true);
      setCart(false);
      setCourses(false);
      setTrainings(false);
    }
  };

  const handleChange = (event) => {
    setDate(event.target.value);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location = "/login";
  };
  /*///////////////////////////////////*/
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //  useEffect(()=>{
  //  	if(scrollPosition>=300){
  //  		window.document.querySelector(".styles_scndInfos__+lj3r").style.marginTop=-320 + "px"
  //  	}else{
  //  		window.document.querySelector(".styles_scndInfos__+lj3r").style.marginTop=0 + "px"
  //  	}
  //  },[scrollPosition])
  /*//////////////////////////////////////////////////////////////////////////*/
  /************/ //////////////////////// */
  const [WindowWidth, setWindowWidth] = useState(0);
  const handleWidthChange = () => {
    const currentWidth = window.innerWidth;
    setWindowWidth(currentWidth);
  };

  useEffect(() => {
    handleWidthChange();
    window.addEventListener("resize", handleWidthChange);
    return () => {
      window.removeEventListener("resize", handleWidthChange);
    };
  }, []);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    if (WindowWidth <= 756) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, []);
  useEffect(() => {
    if (WindowWidth <= 756) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [WindowWidth]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Header />
      <Profile />
      <Footer />
    </div>
  );
};

export default ProfileTrainer;

// <React.Fragment>
//       <Nav />
//       <main className={styles.MotherDivProfile}>
//         <div className={styles.MainDivProfile}>
//           {profile ? (
//             <InfoUser user={user} allCourses={allCourses} />
//           ) : (
//             <React.Fragment>
//               {cart ? (
//                 <Cart user={user} />
//               ) : (
//                 <React.Fragment>
//                   {courses ? (
//                     <Courses user={user} />
//                   ) : (
//                     <React.Fragment>
//                       {trainings ? <Trainings user={user} /> : ""}
//                     </React.Fragment>
//                   )}
//                 </React.Fragment>
//               )}
//             </React.Fragment>
//           )}
//           <div className={styles.rightSectionProfile}>
//             <div className={styles.scndInfos}>
//               <h5 className={styles.titleWelcome}>welcome {user.name}</h5>
//               <Button
//                 onClick={handleProfile}
//                 className={styles.Button}
//                 variant="outlined"
//                 startIcon={<AccountCircleIcon />}
//               >
//                 Personnal Informations
//               </Button>

//               <Button
//                 onClick={handleCart}
//                 className={styles.Button}
//                 variant="outlined"
//                 startIcon={<ShoppingBasketIcon />}
//               >
//                 Cart
//               </Button>

//               <Button
//                 onClick={handleTrainings}
//                 className={styles.Button}
//                 variant="outlined"
//                 startIcon={<HistoryEduIcon />}
//               >
//                 Trainings
//               </Button>
//               <Button
//                 onClick={handleCourses}
//                 className={styles.Button}
//                 variant="outlined"
//                 startIcon={<OndemandVideoIcon />}
//               >
//                 Courses
//               </Button>

//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-around",
//                   width: "100%",
//                   padding: "2rem",
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     border :'1px solid grey',
//                     borderRadius : 15,
//                     padding : '1rem'
//                   }}
//                 >
//                   <img src="/svg/coins.svg" style={{ height: 50 }} alt="" />
//                   <strong
//                     variant="caption"
//                     component="div"
//                     color="text.secondary"
//                   >
//                     330
//                   </strong>
//                 </div>
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     border :'1px solid grey',
//                     borderRadius : 15,
//                     padding : '1rem'
//                   }}
//                 >
//                   <img
//                     src="/svg/bronze.svg"
//                     alt="bronze"
//                     style={{ height: 50 }}
//                   />
//                   <strong
//                     variant="caption"
//                     component="div"
//                     color="text.secondary"
//                   >
//                     Bronze
//                   </strong>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>
//       <Footer />
//     </React.Fragment>
