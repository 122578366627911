import './styles.css'
import React from 'react'

const Loading =()=>
  <div className="allLoading">
    <div className="loading">
      <div></div>
      <div></div>
    </div>
  </div> 

export default Loading